import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LibraryBooks, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
} from "@mui/material";
import { useStores } from "../../stores/RootStore";
import { toast } from "react-toastify";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import UndoButton from "../../../../../components/Dialog/Buttons/UndoButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import OrderMCDeliveryContent from "./OrderMCDeliveryContent";
import OrderMCManagementDetailContent from "./OrderMCManagementDetailContent";
import { getCurrencyEnumOptions } from "../../../../../utils/SelectOptions";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { getUserUpdatePermission } from "../../../Details/utils/EditableFieldsUtils";
import FinancialUserDeliveryContent from "./FinancialUserDeliveryContent";
import EngineeringUserContent from "./EngineeringUserContent";
import { getFollowUpArgProbabilityOptions } from "../../../../../utils/enums/ArgDeliveryProbabilityEnum";

const FollowUpArgBatchEditionModal: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);
	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const { isAuthorizedGroup } = useAuthStore();
	const userUpdatePermission = getUserUpdatePermission(isAuthorizedGroup);

	const {
		resetData,
		updateBatchData,
		formHasChanges,
		setOptionsDeliveryProbability,
		setOptionsCurrency,
		setOptionsDealer,
		setOptionsVehicleLocation,
		setOptionsPaqueteForm,
		setOptionsPaymentForm,
		itemsToBeUpdated,
	} = useStores().batchEditionStore;

	const validListSize = itemsToBeUpdated.length > 0;

	const fetchOptions = React.useCallback(() => {
		setOptionsDeliveryProbability(getFollowUpArgProbabilityOptions(t));
		setOptionsCurrency(getCurrencyEnumOptions(t));
		setOptionsPaymentForm(language);
		setOptionsVehicleLocation();
		setOptionsPaqueteForm();
		setOptionsDealer();
	}, [
		setOptionsDeliveryProbability,
		t,
		setOptionsCurrency,
		setOptionsPaymentForm,
		language,
		setOptionsVehicleLocation,
		setOptionsPaqueteForm,
		setOptionsDealer,
	]);

	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	const [open, setOpen] = React.useState(false);

	const setModalOpen = () => {
		resetData();
		setOpen(true);
	};
	const setModalClosed = () => setOpen(false);

	const handleUndo = () => {
		resetData();
		setModalClosed();
	};
	const handleSubmit = () => {
		updateBatchData(userUpdatePermission)
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data));
		setModalClosed();
	};

	const renderBatchEditionInputFields = () => {
		switch (userUpdatePermission) {
			case "UpdateFollowUpArg":
				return (
					<Grid container spacing={2} overflow="none">
						<OrderMCDeliveryContent />
						<OrderMCManagementDetailContent />
					</Grid>
				);

			case "UpdateFollowUpArgFinancial":
				return (
					<Grid container spacing={2} overflow="none">
						<FinancialUserDeliveryContent />
					</Grid>
				);

			case "UpdateFollowUpArgEngineering":
				return (
					<Grid container spacing={2} overflow="none">
						<EngineeringUserContent />
					</Grid>
				);

			default:
				return;
		}
	};

	return (
		<>
			<Box p={2}>
				<Typography>
					<Button
						variant="contained"
						onClick={setModalOpen}
						color="secondary"
						size="small"
						disabled={!validListSize}
						startIcon={<LibraryBooks />}
					>
						{t("batchEdition", { ns: "general" })}
					</Button>
				</Typography>
			</Box>
			<DialogForm
				id="dialog-batch-edition"
				open={open}
				title={t("batchEdition", { ns: "general" })}
				onClose={setModalClosed}
			>
				<DialogContent>{renderBatchEditionInputFields()}</DialogContent>
				<DialogActions>
					<UndoButton
						id="dialog-batch-edition"
						onUndo={handleUndo}
						disabled={!formHasChanges}
						title={t("undoChanges", { ns: "general" })}
					/>
					<ApplyButton
						id="dialog-batch-edition"
						onSubmit={handleSubmit}
						title={t("saveChanges", { ns: "general" })}
						startIcon={<Save />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(FollowUpArgBatchEditionModal);
