import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { FD_MKT_COMPANY_EUROPE } from "../../../../../constants/Menus/OrderMenuUtils";
import HomePageGrid from "../../components/HomePage/HomePageGrid";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { getEuropeGridColumns } from "../../utils/GridUtils";
import { Box, Grid } from "@mui/material";
import EuropeFactoryPreFilters from "../../components/PreFilters/EuropeFactoryPreFilters";
import HomePageActions from "../../components/HomePage/HomePageActions";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeCountryPreFilterOption } from "../../../../../constants/AuthGroups/orderDetails";

const EuropeFactoryDeliveries: React.FC = () => {
	const { t } = useTranslation(["order", "menu", "europe"]);
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	React.useEffect(() => {
		reset();
		setMenu(FD_MKT_COMPANY_EUROPE);
		const gridColumns = getEuropeGridColumns(t);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isAuthorizedGroup } = useAuthStore();
	const userCanSeeCountryColumn = isAuthorizedGroup(
		groupsAllowedToSeeCountryPreFilterOption
	);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("orderBookFactoryDeliveries.orderBook", { ns: "menu" })} - 
							${t("europeFactory.title", { ns: "menu" })} - 
							${t("orderBookFactoryDeliveries.marketCompany", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			{menu === FD_MKT_COMPANY_EUROPE && (
				<>
					<Grid item lg={12}>
						<Box sx={{ display: "flex" }}>
							<TitleBox
								breadcrumbs={breadcrumbs}
								pageTitle={t("orderBookFactoryDeliveries.pageTitle", {
									ns: "menu",
									fd: t("europeFactory.title", { ns: "menu" }),
								})}
							/>
							<HomePageActions menu={FD_MKT_COMPANY_EUROPE} />
							<EuropeFactoryPreFilters />
						</Box>
					</Grid>
					<Grid item lg={12}>
						{columns && (
							<HomePageGrid
								columns={columns.filter(
									(col: IColumnProps) =>
										col.field !== "countryName" || userCanSeeCountryColumn
								)}
							/>
						)}
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(EuropeFactoryDeliveries);
