import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { DeleteForever, DeleteOutline } from "@mui/icons-material";

const VolvoManagementGeneralCommentContent: React.FC = () => {
	const { t } = useTranslation("order");

	const { batchData, setVolvoManagementGeneralComment } =
		useStores().batchEditionStore;

	return (
		<>
			<Grid item xs={11}>
				<TextForm
					label={t("details.sections.generalComments.title")}
					editable
					value={batchData.generalComment.value}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setVolvoManagementGeneralComment({
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.generalComment.clearValue}
					helperText={t("details.sections.generalComments.warningText")}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.generalComments.title"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.generalComment.clearValue}
						color="secondary"
						onChange={(e) =>
							setVolvoManagementGeneralComment({
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
		</>
	);
};

export default observer(VolvoManagementGeneralCommentContent);
