import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { DeleteForever, DeleteOutline } from "@mui/icons-material";

const OrderMCDeliveryContent: React.FC = () => {
	const { t } = useTranslation("order");

	const {
		batchData,
		deliveryProbabilityOptions,
		setMCDeliveryDataByKey,
		setDeliveryProbability,
	} = useStores().batchEditionStore;

	return (
		<>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyDelivery.deliveryProbability")}
					labelId={
						"details.sections.mktCompanyDelivery.deliveryProbability-label"
					}
					value={batchData.orderMCDelivery.deliveryProbability.value}
					options={deliveryProbabilityOptions}
					size="small"
					editable
					onChange={(e: any) => setDeliveryProbability(e.target.value)}
				/>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.forecastBilling.billingForecast")}
					inputFormat="MMMM/yy"
					value={batchData.orderMCDelivery.billingForecast.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey("billingForecast", {
							value: event,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCDelivery.billingForecast.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.forecastBilling.billingForecast"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCDelivery.billingForecast.clearValue}
						color="secondary"
						onChange={(e) =>
							setMCDeliveryDataByKey("billingForecast", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyDelivery.firmBillingDate")}
					inputFormat="dd/MM/yy"
					value={batchData.orderMCDelivery.firmBilling.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey("firmBilling", {
							value: event,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCDelivery.firmBilling.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyDelivery.firmBillingDate"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCDelivery.firmBilling.clearValue}
						color="secondary"
						onChange={(e) =>
							setMCDeliveryDataByKey("firmBilling", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyDelivery.financialDelivery")}
					inputFormat="dd/MM/yy"
					value={batchData.orderMCDelivery.financialDelivery.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey("financialDelivery", {
							value: event,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCDelivery.financialDelivery.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyDelivery.financialDelivery"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCDelivery.financialDelivery.clearValue}
						color="secondary"
						onChange={(e) =>
							setMCDeliveryDataByKey("financialDelivery", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyDelivery.physicalDelivery")}
					inputFormat="dd/MM/yy"
					value={batchData.orderMCDelivery.physicalDelivery.value}
					editable
					onChange={(event: any) =>
						setMCDeliveryDataByKey("physicalDelivery", {
							value: event,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCDelivery.physicalDelivery.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyDelivery.physicalDelivery"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCDelivery.physicalDelivery.clearValue}
						color="secondary"
						onChange={(e) =>
							setMCDeliveryDataByKey("physicalDelivery", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
		</>
	);
};

export default observer(OrderMCDeliveryContent);
