import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { INTERNAL_VDB } from "../../../../../constants/Menus/OrderMenuUtils";
import HomePageGrid from "../../components/HomePage/HomePageGrid";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { getGridColumnsByMenu } from "../../utils/GridUtils";
import { Box, Grid } from "@mui/material";
import TrucksPreFilters from "../../components/PreFilters/TrucksPreFilters";
import HomePageActions from "../../components/HomePage/HomePageActions";
const InternalVdb: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	React.useEffect(() => {
		reset();
		setMenu(INTERNAL_VDB);
		const gridColumns = getGridColumnsByMenu(t, INTERNAL_VDB);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("orderBookTrucks.orderBook", { ns: "menu" })} - ${t(
				"orderBookTrucks.internalVdb",
				{ ns: "menu" }
			)}`,
			main: true,
		},
	];

	return (
		<>
			{menu === INTERNAL_VDB && (
				<>
					<Grid item lg={12}>
						<Box sx={{ display: "flex" }}>
							<TitleBox
								breadcrumbs={breadcrumbs}
								pageTitle={t("orderBookTrucks.pageTitle", {
									ns: "menu",
									ob: t("orderBookTrucks.internalVdb", { ns: "menu" }),
								})}
							/>
							<HomePageActions menu={INTERNAL_VDB} />
							<TrucksPreFilters />
						</Box>
					</Grid>
					<Grid item lg={12}>
						{columns && <HomePageGrid columns={columns} />}
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(InternalVdb);
