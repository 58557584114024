import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";

interface AccordionCardProps {
	title: string;
	id: string;
	summaryBody?: React.ReactNode;
	children?: React.ReactNode;
	defaultExpanded?: boolean;
	overflow?: string;
	gridNumber?: number;
	preTitle?: React.ReactNode;
	style?: React.CSSProperties;
}

const AccordionCard: React.FC<AccordionCardProps> = ({
	title,
	id,
	summaryBody,
	children,
	defaultExpanded = false,
	overflow = "none",
	gridNumber = 12,
	preTitle,
	style,
}) => {
	return (
		<Grid item xs={gridNumber}>
			<Accordion
				variant="outlined"
				id={id}
				defaultExpanded={defaultExpanded}
				className="scrollAccordion"
				style={style}
			>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Stack direction="row" spacing={1} alignItems="center">
						{preTitle}
						<Typography gutterBottom variant="h6" component="h2">
							{title.toUpperCase()}
						</Typography>
					</Stack>
					{summaryBody}
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2} overflow={overflow}>
						{children}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};

export default AccordionCard;
