import React from "react";
import {
	Autocomplete,
	createFilterOptions,
	TextField,
	Tooltip,
} from "@mui/material";
import { Edit, Info } from "@mui/icons-material";
import { CSSProperties } from "@mui/material/styles/createMixins";

type MenuItemProps = {
	label: string;
	value: any;
	searchValue?: any;
};

type AutoCompleteFormProps = {
	options: Array<MenuItemProps>;
	validator?: (value: string, name?: string) => string;
	onChange?: (value: any) => void;
	name?: string;
	label: string | React.ReactNode;
	labelId: string;
	value: any;
	editable?: boolean;
	hasEmptyDefaultItem?: boolean;
	hasEditIcon?: boolean;
	isCustomSearch?: boolean;
	editTooltip?: string;
	style?: CSSProperties | undefined;
	listboxStyle?: CSSProperties | undefined;
	disabled?: boolean;
};

const AutoCompleteForm: React.FC<AutoCompleteFormProps> = ({
	options,
	onChange,
	validator,
	name,
	label,
	labelId,
	hasEditIcon,
	hasEmptyDefaultItem,
	editable,
	isCustomSearch,
	editTooltip,
	style,
	listboxStyle,
	...props
}) => {
	const filtered = options.filter((f) => f.value === props.value);

	const errorMessage = validator && validator(props.value, name);

	const onChangeHandler = (e: any, value: any) => {
		if (!onChange) return undefined;

		const newValue = value?.value ?? value ?? "";

		return onChange(newValue);
	};

	options =
		editable && hasEmptyDefaultItem
			? [{ label: "", value: "", searchValue: "" }, ...options]
			: options;

	hasEditIcon = editable && hasEditIcon;

	const filterOptions = createFilterOptions({
		matchFrom: "any",
		stringify: (option: MenuItemProps) => option.searchValue,
	});

	if (editable && editTooltip)
		label = (
			<>
				{label}{" "}
				<Tooltip title={editTooltip}>
					<Info sx={{ paddingTop: 1 }} />
				</Tooltip>{" "}
			</>
		);

	return (
		<Autocomplete
			{...props}
			style={style}
			readOnly={!editable}
			id={labelId}
			disablePortal
			options={options}
			popupIcon={hasEditIcon ? <Edit /> : undefined}
			sx={{
				"& .MuiAutocomplete-popupIndicator": {
					transform: hasEditIcon ? "none" : "",
				},
			}}
			getOptionLabel={(option: MenuItemProps) => option.label}
			value={filtered && filtered.length > 0 ? filtered[0] : null}
			onChange={onChangeHandler}
			renderInput={(params) => (
				<TextField
					{...params}
					InputLabelProps={{
						shrink: true,
					}}
					variant="standard"
					error={Boolean(errorMessage)}
					helperText={errorMessage}
					fullWidth
					label={label}
				/>
			)}
			filterOptions={isCustomSearch ? filterOptions : undefined}
			ListboxProps={{ style: listboxStyle }}
		/>
	);
};

export default AutoCompleteForm;
