import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { useStores } from "../../stores/RootStore";

const QuotaContent: React.FC = () => {
	const { t } = useTranslation("order");

	const {
		districtsList,
		regionsList,
		loadDistrictsAndRegionsList,
		batchData,
		setQuotaDataByKey,
	} = useStores().batchEditionStore;

	const loadOptions = React.useCallback(() => {
		loadDistrictsAndRegionsList();
	}, [loadDistrictsAndRegionsList]);

	React.useEffect(() => {
		loadOptions();
	}, [loadOptions]);

	return (
		<>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.quotaInformation.quotaDistrict")}
					labelId={"sections.quotaInformation-quotaDistrict-label"}
					value={batchData.quotaData.quotaDistrict}
					options={districtsList}
					editable
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setQuotaDataByKey("quotaDistrict", e.target.value as string)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.quotaInformation.quotaRegion")}
					labelId={"sections.quotaInformation-quotaRegion-label"}
					value={batchData.quotaData.quotaRegion}
					options={regionsList}
					editable
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setQuotaDataByKey("quotaRegion", e.target.value as string)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(QuotaContent);
