import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { DeleteForever, DeleteOutline } from "@mui/icons-material";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getCurrencyOptions } from "../../../../../utils/SelectOptions";
import DateForm from "../../../../../components/FormInputs/DateForm";

const OrderMCManagementDetailContent: React.FC = () => {
	const { t } = useTranslation("order");
	const currencyOptions = getCurrencyOptions();

	const {
		batchData,
		paymentTermsOptions,
		vehicleLocationOptions,
		dealerOptions,
		setCustomerSAS,
		setMCManagementDetailDestination,
		setOrderMCManagementDetailBatchEditByKey,
		paqueteOptions,
	} = useStores().batchEditionStore;

	return (
		<>
			<Grid item xs={3}>
				<SelectForm
					label={t("details.sections.customer.dealer")}
					labelId={"sections.customer-dealer-label"}
					value={batchData.orderMCManagementDetail.dealerId.value}
					options={dealerOptions}
					size="small"
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey("dealerId", {
							value: e.target.value as string,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.dealerId.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.customer.dealer"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCManagementDetail.dealerId.clearValue}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("dealerId", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={5}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					editable
					value={batchData.customerSAS.value}
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setCustomerSAS({
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.customerSAS.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.customer.sasCustomer"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.customerSAS.clearValue}
						color="secondary"
						onChange={(e) =>
							setCustomerSAS({
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={5}>
				<TextForm
					label={t("details.sections.forecastBilling.destination")}
					editable
					value={batchData.orderMCManagementDetail.destination.value}
					inputProps={{ maxLength: 150 }}
					onChange={(e) =>
						setMCManagementDetailDestination({
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.destination.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={3}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.forecastBilling.destination"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCManagementDetail.destination.clearValue}
						color="secondary"
						onChange={(e) =>
							setMCManagementDetailDestination({
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={2}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.currency")}
					labelId={"details.sections.mktCompanyCommercial.currency-label"}
					value={batchData.orderMCManagementDetail.currency.value}
					options={currencyOptions}
					size="small"
					editable
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey("currency", {
							value: e.target.value as string,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.currency.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyCommercial.currency"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCManagementDetail.currency.clearValue}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("currency", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.paymentForm")}
					labelId={"sections.mktCompanyCommercial-paymentForm-label"}
					value={batchData.orderMCManagementDetail.paymentTermsId.value}
					options={paymentTermsOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey("paymentTermsId", {
							value: e.target.value as string,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.paymentTermsId.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyCommercial.paymentForm"),
					})}
					arrow
				>
					<Checkbox
						checked={
							batchData.orderMCManagementDetail.paymentTermsId.clearValue
						}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("paymentTermsId", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.forecastBilling.shippingDate")}
					value={batchData.orderMCManagementDetail.shippingDate.value}
					editable
					onChange={(event: any) =>
						setOrderMCManagementDetailBatchEditByKey("shippingDate", {
							value: event,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.shippingDate.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.forecastBilling.shippingDate"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCManagementDetail.shippingDate.clearValue}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("shippingDate", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.forecastBilling.certificateDate")}
					value={batchData.orderMCManagementDetail.certificateDate.value}
					editable
					onChange={(event: any) =>
						setOrderMCManagementDetailBatchEditByKey("certificateDate", {
							value: event,
							clearValue: false,
						})
					}
					disabled={
						batchData.orderMCManagementDetail.certificateDate.clearValue
					}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.forecastBilling.certificateDate"),
					})}
					arrow
				>
					<Checkbox
						checked={
							batchData.orderMCManagementDetail.certificateDate.clearValue
						}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("certificateDate", {
								value: null,
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.paquete")}
					labelId={"sections.mktCompanyCommercial-paquete-label"}
					value={batchData.orderMCManagementDetail.paqueteId.value}
					options={paqueteOptions}
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey("paqueteId", {
							value: e.target.value as string,
							clearValue: false,
						})
					}
					disabled={batchData.orderMCManagementDetail.paqueteId.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyCommercial.paquete"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.orderMCManagementDetail.paqueteId.clearValue}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("paqueteId", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>

			<Grid item xs={11}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.vehicleLocation")}
					labelId={"sections.mktCompanyCommercial-vehicleLocation-label"}
					value={batchData.orderMCManagementDetail.vehicleLocationId.value}
					options={vehicleLocationOptions}
					editable
					hasEmptyDefaultItem
					hasEditIcon
					onChange={(e) =>
						setOrderMCManagementDetailBatchEditByKey("vehicleLocationId", {
							value: e.target.value as string,
							clearValue: false,
						})
					}
					disabled={
						batchData.orderMCManagementDetail.vehicleLocationId.clearValue
					}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.mktCompanyCommercial.vehicleLocation"),
					})}
					arrow
				>
					<Checkbox
						checked={
							batchData.orderMCManagementDetail.vehicleLocationId.clearValue
						}
						color="secondary"
						onChange={(e) =>
							setOrderMCManagementDetailBatchEditByKey("vehicleLocationId", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
		</>
	);
};

export default observer(OrderMCManagementDetailContent);
