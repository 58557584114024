import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LibraryBooks, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
} from "@mui/material";
import { useStores } from "../../stores/RootStore";
import { toast } from "react-toastify";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import UndoButton from "../../../../../components/Dialog/Buttons/UndoButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import OrderContent from "./OrderContent";

const FollowUpChlBatchEditionModal: React.FC = () => {
	const { t } = useTranslation("general");
	const { listTotalResult } = useStores().listStore;

	const { resetData, updateBatchData, formHasChanges } =
		useStores().batchEditionStore;

	const validListSize = listTotalResult > 0 && listTotalResult <= 80;

	const [open, setOpen] = React.useState(false);

	const setModalOpen = () => {
		resetData();
		setOpen(true);
	};

	const setModalClosed = () => setOpen(false);

	const handleUndo = () => {
		resetData();
		setModalClosed();
	};

	const handleSubmit = () => {
		updateBatchData()
			.then(() => toast.success(t("editRecordsWithSuccess").toString()))
			.catch((err: any) => toast.error(err.response.data));
		setModalClosed();
	};

	return (
		<>
			<Box p={2}>
				<Typography>
					<Button
						variant="contained"
						onClick={setModalOpen}
						color="secondary"
						size="small"
						disabled={!validListSize}
						startIcon={<LibraryBooks />}
					>
						{t("batchEdition")}
					</Button>
				</Typography>
			</Box>
			<DialogForm
				id="dialog-batch-edition"
				open={open}
				title={t("batchEdition")}
				onClose={setModalClosed}
			>
				<DialogContent>
					<Grid container spacing={2} overflow="none">
						<OrderContent />
					</Grid>
				</DialogContent>
				<DialogActions>
					<UndoButton
						id="dialog-batch-edition"
						onUndo={handleUndo}
						disabled={!formHasChanges}
						title={t("undoChanges")}
					/>
					<ApplyButton
						id="dialog-batch-edition"
						onSubmit={handleSubmit}
						title={t("saveChanges")}
						startIcon={<Save />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(FollowUpChlBatchEditionModal);
