import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getChileZoneOptions } from "../../../../../utils/SelectOptions";
import { DeleteForever, DeleteOutline } from "@mui/icons-material";

const OrderContent: React.FC = () => {
	const { t } = useTranslation("order");
	const chlZonesOptions = getChileZoneOptions(t);

	const { batchData, customerOptions, setBatchDataByKey, setCustomerOptions } =
		useStores().batchEditionStore;

	const loadOptions = React.useCallback(() => {
		setCustomerOptions();
	}, [setCustomerOptions]);

	React.useEffect(() => {
		loadOptions();
	}, [loadOptions]);

	return (
		<>
			<Grid item xs>
				<SelectForm
					label={t("preFilters.zone")}
					labelId={"pre-filter-zone-label"}
					value={batchData.zone.value}
					options={chlZonesOptions}
					size="small"
					editable
					hasEmptyDefaultItem
					onChange={(e) =>
						setBatchDataByKey("zone", {
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.zone.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Checkbox
					checked={batchData.zone.clearValue}
					color="secondary"
					onChange={(e) =>
						setBatchDataByKey("zone", {
							value: "",
							clearValue: e.target.checked,
						})
					}
					icon={<DeleteOutline />}
					checkedIcon={<DeleteForever />}
				/>
			</Grid>
			<Grid item xs>
				<SelectForm
					label={t("details.sections.customer.title")}
					labelId={"details.sections.customer"}
					options={customerOptions}
					value={batchData.customer.value}
					editable
					onChange={(e) =>
						setBatchDataByKey("customer", {
							value: e.target.value,
							clearValue: false,
						})
					}
					hasEmptyDefaultItem
					hasEditIcon
					disabled={batchData.customer.clearValue}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Checkbox
					checked={batchData.customer.clearValue}
					color="secondary"
					onChange={(e) =>
						setBatchDataByKey("customer", {
							value: "",
							clearValue: e.target.checked,
						})
					}
					icon={<DeleteOutline />}
					checkedIcon={<DeleteForever />}
				/>
			</Grid>
			<Grid item xs={11}>
				<TextForm
					label={t("details.sections.generalComments.title")}
					editable
					value={batchData.generalComment.value}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setBatchDataByKey("generalComment", {
							value: e.target.value,
							clearValue: false,
						})
					}
					disabled={batchData.generalComment.clearValue}
					helperText={t("tooltips.brFactoryOnly")}
				/>
			</Grid>
			<Grid item xs={1} mt={2}>
				<Tooltip
					title={t("tooltips.clearRecord", {
						record: t("details.sections.generalComments.title"),
					})}
					arrow
				>
					<Checkbox
						checked={batchData.generalComment.clearValue}
						color="secondary"
						onChange={(e) =>
							setBatchDataByKey("generalComment", {
								value: "",
								clearValue: e.target.checked,
							})
						}
						icon={<DeleteOutline />}
						checkedIcon={<DeleteForever />}
					/>
				</Tooltip>
			</Grid>
		</>
	);
};

export default observer(OrderContent);
