import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { getBreadcrumbs } from "../utils/BreadcrumbsUtils";
import { Box, Grid } from "@mui/material";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import HomePageGrid from "../components/HomePage/HomePageGrid";
import TrucksPreFilters from "../components/PreFilters/TrucksPreFilters";
import { useStores } from "../stores/RootStore";
import FollowUpChlBatchEditionModal from "../components/BatchEdition/FollowUpChlBatchEditionModal";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import { groupsToBatchEditFollowUpChl } from "../../../../constants/AuthGroups/followUpChlPage";
import HomePageCsvExport from "./HomePageCsvExport";

const Index: React.FC = () => {
	const { t } = useTranslation("menu");
	const { isAuthorizedGroup } = useAuthStore();
	const userCanDoBatchEdition = isAuthorizedGroup(groupsToBatchEditFollowUpChl);

	const breadcrumbs = getBreadcrumbs(t);
	const { reset } = useStores().listStore;

	React.useEffect(() => {
		reset();
	}, [reset]);

	return (
		<>
			<Grid item lg={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					<HomePageCsvExport />
					{userCanDoBatchEdition && <FollowUpChlBatchEditionModal />}
					<TrucksPreFilters />
				</Box>
			</Grid>
			<HomePageGrid />
			<ToastContainer />
		</>
	);
};

export default observer(Index);
