import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LibraryBooks, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
} from "@mui/material";
import QuotaContent from "./QuotaContent";
import VolvoManagementContent from "./VolvoManagementContent";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditFinalCustomerBatchDetails,
	groupsAllowedToEditQuotaSection,
	groupsAllowedToEditVolvoManagementDetails,
} from "../../../../../constants/AuthGroups/orderDetails";
import { toast } from "react-toastify";
import {
	BUS_BRAZIL,
	EXTERNAL_BRAZIL,
} from "../../../../../constants/Menus/OrderMenuUtils";
import VolvoManagementGeneralCommentContent from "./VolvoManagementGeneralCommentContent";
import CustomerContent from "./CustomerContent";
import FinalCustomerContent from "./FinalCustomerContent";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import UndoButton from "../../../../../components/Dialog/Buttons/UndoButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";

const EditionModal: React.FC = () => {
	const { t } = useTranslation("general");

	const { resetData, updateBatchData, formHasChanges } =
		useStores().batchEditionStore;
	const { listTotalResult, menu } = useStores().listStore;

	const {
		isAuthorizedGroup,
		isAuthorizedBraDealerGroup,
		isAuthorizedAdminGroup,
		graphData,
	} = useAuthStore();

	const isAdmin = isAuthorizedAdminGroup();
	const dealerCanEdit =
		isAuthorizedBraDealerGroup() &&
		[EXTERNAL_BRAZIL, BUS_BRAZIL].includes(menu);

	const userCanEditQuotaContent = isAuthorizedGroup(
		groupsAllowedToEditQuotaSection
	);
	const userCanEditVolvoManagementContent = isAuthorizedGroup(
		groupsAllowedToEditVolvoManagementDetails
	);
	const userCanEditFinalCustomerContent =
		dealerCanEdit ||
		isAuthorizedGroup(groupsAllowedToEditFinalCustomerBatchDetails);
	const userCanEditVolvoManagementGeneralCommentContent =
		dealerCanEdit || userCanEditVolvoManagementContent;
	const userCanEditCustomerContent = dealerCanEdit || isAdmin;

	const userCanDoBatchEdition =
		userCanEditQuotaContent ||
		userCanEditVolvoManagementContent ||
		userCanEditVolvoManagementGeneralCommentContent ||
		userCanEditCustomerContent;

	const validListSize = listTotalResult > 0 && listTotalResult <= 80;

	const [open, setOpen] = React.useState(false);

	const setModalOpen = () => setOpen(true);
	const setModalClosed = () => setOpen(false);

	const handleUndo = () => {
		resetData();
		setModalClosed();
	};
	const handleSubmit = async () => {
		const userEmail = graphData?.mail ?? "";
		await updateBatchData(userEmail)
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => {
				toast.error(err.response.data);
				resetData();
			});
		setModalClosed();
	};

	return (
		<>
			{userCanDoBatchEdition && (
				<>
					<Box p={2}>
						<Typography>
							<Button
								variant="contained"
								onClick={setModalOpen}
								color="secondary"
								size="small"
								disabled={!validListSize}
								startIcon={<LibraryBooks />}
							>
								{t("batchEdition")}
							</Button>
						</Typography>
					</Box>
					<DialogForm
						id="dialog-batch-edition"
						open={open}
						title={t("batchEdition")}
						onClose={setModalClosed}
					>
						<DialogContent>
							<Grid container spacing={2} overflow="none">
								{userCanEditCustomerContent && <CustomerContent />}
								{userCanEditQuotaContent && <QuotaContent />}
								{userCanEditFinalCustomerContent && <FinalCustomerContent />}
								{userCanEditVolvoManagementContent && (
									<VolvoManagementContent />
								)}
								{userCanEditVolvoManagementGeneralCommentContent && (
									<VolvoManagementGeneralCommentContent />
								)}
							</Grid>
						</DialogContent>
						<DialogActions>
							<UndoButton
								id="dialog-batch-edition"
								onUndo={handleUndo}
								disabled={!formHasChanges}
								title={t("undoChanges", { ns: "general" })}
							/>
							<ApplyButton
								id="dialog-batch-edition"
								onSubmit={handleSubmit}
								title={t("saveChanges", { ns: "general" })}
								startIcon={<Save />}
							/>
						</DialogActions>
					</DialogForm>
				</>
			)}
		</>
	);
};

export default observer(EditionModal);
