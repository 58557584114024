import React from "react";
import { observer } from "mobx-react";
import HomePageCsvExport from "./HomePageCsvExport";
import {
	BusMenus,
	OrderBookMenuType,
	TruckBrMenus,
} from "../../../../../constants/Menus/OrderMenuUtils";
import EditionModal from "../../containers/BatchEdition/EditionModal";

type HomePageActionsProps = {
	menu: OrderBookMenuType;
};

const HomePageActions: React.FC<HomePageActionsProps> = ({ menu }) => {
	return (
		<>
			<HomePageCsvExport />

			{(TruckBrMenus.some((x) => x === menu) ||
				BusMenus.some((x) => x === menu)) && (
				<>
					<EditionModal />
				</>
			)}
		</>
	);
};

export default observer(HomePageActions);
