import * as React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { DataGridApi } from "../../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../../constants/gridConst";
import { useStores } from "../stores/RootStore";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { toast } from "react-toastify";

type HomePageGrid = {
	columns: Array<IColumnProps>;
	calculateWidth?: boolean;
};

const MenuGrid: React.FC<HomePageGrid> = ({ columns, calculateWidth }) => {
	const { t } = useTranslation("order");
	const { allRecords, refresh } = useStores().listStore;
	const { filters } = useStores().filterStore;
	const { itemsToBeUpdated, onChangeItemToBeUpdated } =
		useStores().batchEditionStore;

	const alertOnDataStateChange = () => {
		if (itemsToBeUpdated && itemsToBeUpdated.length > 0)
			toast.warn(t("cleanSelectionItens").toString());
	};

	return (
		<>
			<DataGridApi
				columns={columns}
				fetchData={allRecords}
				pageable={gridConst.pageable}
				pageSize={gridConst.pageSize}
				filterBy={toJS(filters)}
				rowHeight={10}
				reorderable={true}
				resizable={true}
				className="k-grid-lg"
				calculateWidth={calculateWidth ?? true}
				refresh={refresh}
				itemsToBeUpdated={itemsToBeUpdated}
				onChangeItemToBeUpdated={onChangeItemToBeUpdated}
				alertOnDataStateChange={alertOnDataStateChange}
			/>
		</>
	);
};

export default observer(MenuGrid);
