import { action, configure, makeObservable, observable, toJS } from "mobx";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";
import { QuotaDataProps } from "../interfaces/BatchEdition/QuotaDataProps";
import { VolvoManagementDataProps } from "../interfaces/BatchEdition/VolvoManagementDataProps";
import _ from "lodash";
import moment from "moment";
import { BatchDataProps } from "../interfaces/BatchEdition/BatchDataProps";
import { batchDataModel } from "../models/BatchEdition/BatchDataModel";
import { CustomerDataProps } from "../interfaces/BatchEdition/CustomerDataProps";
import { StringFormProps } from "../interfaces/BatchEdition/StringFormProps";

configure({ enforceActions: "always" });

class BatchEditionStore {
	@observable batchData: BatchDataProps = batchDataModel;

	@observable districtsList: Array<SelectOptionProps> = [];
	@observable regionsList: Array<SelectOptionProps> = [];
	@observable formHasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetData = () => {
		this.resetBatchData();
		this.setFormHasChanges(false);
	};

	@action private resetBatchData = () => (this.batchData = batchDataModel);

	@action setFormHasChanges = (formHasChanges: boolean) =>
		(this.formHasChanges = formHasChanges);

	@action checkAndSetFormHasChanges = () => {
		const batchDataHasChanges = !_.isEqual(batchDataModel, this.batchData);
		this.setFormHasChanges(batchDataHasChanges);
	};

	@action setQuotaDataByKey = <K extends keyof QuotaDataProps>(
		fieldKey: K,
		newValue: QuotaDataProps[K]
	) => {
		const _quotaData: QuotaDataProps = Object.assign(
			{},
			this.batchData.quotaData
		);
		_quotaData[fieldKey] = newValue;
		this.batchData.quotaData = _quotaData;
		this.checkAndSetFormHasChanges();
	};

	@action loadDistrictsAndRegionsList = async () => {
		if (this.districtsOrRegionHasData()) return;

		const loadDistrictsList_request = this.api.districtService.getList();
		const loadRegionsList_request = this.api.regionService.getList();

		await Promise.all([
			loadDistrictsList_request,
			loadRegionsList_request,
		]).then((responses) => {
			this.setDistrictsList(responses[0].data);
			this.setRegionsList(responses[1].data);
		});
	};

	@action private districtsOrRegionHasData = () =>
		this.districtsList.length > 0 || this.regionsList.length > 0;

	@action private setDistrictsList = (districts: any) => {
		this.districtsList = districts.map((value: string) => ({
			label: value,
			value: value,
		}));
	};

	@action private setRegionsList = (regions: any) => {
		this.regionsList = regions.map((value: string) => ({
			label: value,
			value: value,
		}));
	};

	@action setVolvoManagementDataByKey = <
		K extends keyof VolvoManagementDataProps,
	>(
		fieldKey: K,
		newValue: VolvoManagementDataProps[K]
	) => {
		const _volvoManagementData: VolvoManagementDataProps = Object.assign(
			{},
			this.batchData.volvoManagementData
		);
		_volvoManagementData[fieldKey] = newValue;
		this.batchData.volvoManagementData = _volvoManagementData;
		this.checkAndSetFormHasChanges();
	};

	@action setVolvoManagementGeneralComment = (newValue: StringFormProps) => {
		this.batchData.generalComment = newValue;
		this.checkAndSetFormHasChanges();
	};

	@action setFinalCustomerComment = (newValue: StringFormProps) => {
		this.batchData.finalCustomer = newValue;
		this.checkAndSetFormHasChanges();
	};

	@action setCustomerDataByKey = <K extends keyof CustomerDataProps>(
		fieldKey: K,
		newValue: CustomerDataProps[K]
	) => {
		const _customerData: CustomerDataProps = Object.assign(
			{},
			this.batchData.customerData
		);
		_customerData[fieldKey] = newValue;
		this.batchData.customerData = _customerData;
		this.checkAndSetFormHasChanges();
	};

	@action private setBatchData = (userEmail: string) => {
		this.batchData = {
			...this.batchData,
			userEmail,
			updateDate: moment().toDate(),
		};
	};

	@action updateBatchData = async (userEmail: string) => {
		this.rootStore.listStore.setLoading(true);
		this.setBatchData(userEmail);
		const batchData = toJS(this.batchData);
		return await this.api.orderService
			.updateBatch(
				this.rootStore.listStore.lastDataState,
				batchData,
				this.rootStore.listStore.menu
			)
			.then(() => this.resetData())
			.finally(() => {
				this.rootStore.listStore.setLoading(false);
				this.rootStore.listStore.setRefresh();
			});
	};
}

export default BatchEditionStore;
