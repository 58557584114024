import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { useStores } from "../../stores/RootStore";
import { getBooleanOptions } from "../../../../../utils/SelectOptions";
import TextForm from "../../../../../components/FormInputs/TextForm";

const VolvoManagementContent: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);

	const { batchData, setVolvoManagementDataByKey } =
		useStores().batchEditionStore;

	const booleanOptions = getBooleanOptions(t);

	return (
		<>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.commercial.extraQuota")}
					labelId={"sections.commercial.extraQuota-label"}
					value={batchData.volvoManagementData.extraQuota}
					options={booleanOptions}
					editable
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setVolvoManagementDataByKey("extraQuota", e.target.value as string)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.commercial.priority")}
					labelId={"sections.commercial.priority-label"}
					value={batchData.volvoManagementData.priority}
					options={booleanOptions}
					editable
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setVolvoManagementDataByKey("priority", e.target.value as string)
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.commercialComments.title")}
					editable
					value={batchData.volvoManagementData.commercialComment}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500 }}
					onChange={(e) =>
						setVolvoManagementDataByKey("commercialComment", e.target.value)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(VolvoManagementContent);
